import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { getAuthUserCookie } from "../../../store/services";
import { useNavigate } from "react-router-dom";
import { getURLbyEndPoint } from "../../../store/api";
import axios from "axios";

const MarketingInterest = () => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);

  const fetchNotification = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getNotificationStatus")
      );
      console.log(response);
      if (response.status === 200) {
        const data = response.data.data;
        setNotificationData(data);
      } else {
        alert("Failed to fetch notification data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);
  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader
            heading="MARKETING INTEREST"
            backUrl="/master-profile"
          />
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-b2b-events");
              }}
              disabled={
                !getAuthUserCookie().accessList.includes("b2bConnect")
              }
            >
              B2B EVENT REQUEST
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.b2bConnect !== 0 ? notificationData.b2bConnect : null}
              </span>
            </button>

            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-marketing-demo-request");
              }}
              disabled={
                !getAuthUserCookie().accessList.includes("MarketingTrialReq")
              }
            >
              MARKETING DEMO REQUEST
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.marTrial !== 0 ? notificationData.marTrial : null}
              </span>
            </button>
          </div>

          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              onClick={() => {
                navigate("/master-legalwing-interest");
              }}
              disabled={
                !getAuthUserCookie().accessList.includes("legalWingReq")
              }
            >
              LEGAL WING REQUEST
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.legalWingReq !== 0 ? notificationData.legalWingReq : null}
              </span>
            </button>
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              // onClick={() => {
              //   navigate("/master-b2b-events");
              // }}
              disabled={
                !getAuthUserCookie().accessList.includes("patronMemberReq")
              }
            >
              PATRON MEMBERSHIP REQUEST
              <span
                className="badge bg-primary position-absolute fs-6"
                style={{ top: "-8px", right: "-8px" }}
              >
                {notificationData.patronMem !== 0 ? notificationData.patronMem : null}
              </span>
            </button>
          </div>

          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1 position-relative d-inline-flex align-items-center justify-content-center"
              style={{ width: "35%" }}
              // onClick={() => {
              //   navigate("/master-legalwing-interest");
              // }}
              // disabled={
              //   !getAuthUserCookie().accessList.includes("legalWingReq")
              // }
              disabled
            >
              B2B BOOKINGS
            </button>
          </div>

        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MarketingInterest;
