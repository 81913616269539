 import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import employees from "../../../assets/masterImgs/employees.jpg";
import { HiBackward } from "react-icons/hi2";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {
  OverlayTrigger,
  Popover,
  Button,
  Table,
  Pagination,
  Modal,
  Col,
  Form,
  Badge,
} from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { getURLbyEndPoint } from "../../../store/api";
import axios from "axios";

import DashboardHeader from "../../../components/basic/DashboardHeader";
import { BiLogoGmail } from "react-icons/bi";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillCheckSquare,
  AiFillCloseSquare,
} from "react-icons/ai";
import FileViewModal from "../../../components/basic/fileViewModal";
import { TruncatedText } from "../../../components/basic/textcustomized";
import { getAuthUserCookie } from "../../../store/services";

const ClientAdmin = () => {
  var bootstrap;
  const [target, setTarget] = useState(null);
  const [userId, setUserId] = useState("");
  const [allReviewed, setAllReviewed] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  // Function to open the modal and set certificate URL
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [file, setFile] = useState(null);

  const openModal = (title, file) => {
    setModalTitle(title);
    setFile(file);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  //array for documnets
  const membershipWithType = [
    {
      name: "Small Business",
      options: [
        "membership Form",
        "2 passport size photo",
        "Cover Letter",
        "Inderminity Bond",
        "Self Attested Copy",
        "IEC Certificate",
        "Certification of Registration",
      ],
    },
    {
      name: "StartUp",
      options: [
        "membership Form",
        "2 passport size photo",
        "Cover Letter",
        "Inderminity Bond",
        "Self Attested Copy",
        "Certification of Registration",
      ],
    },
    {
      name: "Corporate",
      options: [
        "membership Form",
        "2 passport size photo",
        "Cover Letter",
        "Inderminity Bond",
        "Self Attested Copy",
        "IEC Certificate",
        "Certification of Registration",
        "Copy of AOA, MOA",
        "Certificate of incorporation",
      ],
    },
    {
      name: "Corporate +",
      options: [
        "membership Form",
        "2 passport size photo",
        "Cover Letter",
        "Inderminity Bond",
        "Self Attested Copy",
        "IEC Certificate",
        "Certification of Registration",
        "Copy of AOA, MOA",
        "Certificate of incorporation",
      ],
    },
    {
      name: "Overseas",
      options: [
        "membership Form",
        "2 passport size photo",
        "Cover Letter",
        "Inderminity Bond",
        "Self Attested Copy",
        "IEC Certificate",
        "Certification of Registration",
        "Passport copies of Authorized Signatures Must",
      ],
    },
    {
      name: "Non Profit Org",
      options: [
        "membership Form",
        "2 passport size photo",
        "Cover Letter",
        "Inderminity Bond",
        "Certification of Registration",
        "Certificate of Incorparation.",
      ],
    },
    {name: "Collaboration",
      options: [
        "Registration Form",
        "Company Profile",
        "Business License",
        "Collaboration Agreement"

      ]

    }
  ];

  //functions and state for messgae setup-------------
  // ----------------------------------------------------
  // ----------------------------------------------------

  const [reason, setReason] = useState("");

  //functions for popover---------------
  // ----------------------------------------

  const handleClosePopover = () => {
    setPopoverVisible(false);
  };

  const [registeredUsers, setRegisteredUsers] = useState([]);
  // const [allRegistrationDocs, setAllRegistrationDocs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchNumber, setSearchNumber] = useState("");

  const [accountStatus, setAccountStatus] = useState("");
  const [userTypeExisting, setUserTypeExisting] = useState(false);
  const [userTypeTrial, setUserTypeTrial] = useState(false);
  const [userTypeCollaboration, setUserTypeCollaboration] = useState(false);
  const [userTypeArbitrator, setUserTypeArbitrator] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState(null);

  const [reviewData, setReviewData] = useState({
    aboutCompany: "Review",
    aboutYou: "Review",
    bankDetails: "Review",
    messageAboutCompany: "",
    messageAboutYou: "",
    messageBankDetails: "",
    documentsChecked: false,
  });

  // function for Review state
  const handleStatusChange = (event) => {
    setAccountStatus(event.target.value);
  };

  //intializing all poopover
  // const popoverTriggerList = document.querySelectorAll(
  //   '[data-bs-toggle="popover"]'
  // );
  // const popoverList = [...popoverTriggerList].map(
  //   (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
  // );

  // Function to show the delete modal and set the deletingItemId
  const handleShowDeleteModal = (userId) => {
    setDeletingItemId(userId);
  };

  // Function to hide the delete modal and reset the deletingItemId
  const handleHideDeleteModal = () => {
    setDeletingItemId(null);
  };

  const handleClickCollaboration = () => {
    // Check if "CollaborationAccessControl" is in the access list
    if (getAuthUserCookie().accessList.includes("CollaborationAccessControl")) {
      return; // Exit early if the condition is true
    }
    // Toggle the state if the condition is not met
    setUserTypeCollaboration(!userTypeCollaboration);
  };



  const handleClickArbitration = () => {
    // Check if "CollaborationAccessControl" is in the access list
    if (getAuthUserCookie().accessList.includes("ArbitrationAccessControl")) {
      return; // Exit early if the condition is true
    }
    // Toggle the state if the condition is not met
    setUserTypeArbitrator(!userTypeArbitrator);
  };

  //function to handle the Rejected operation

  const handleRejected = async (
    userId,
    reviewAboutCompany,
    reviewAboutYou,
    reviewBankDetails,
    messageAboutCompany,
    messageAboutYou,
    messageBankDetails
  ) => {
    try {
      const response = await axios.put(getURLbyEndPoint("rejectApplication"), {
        userId,
        messageAboutCompany,
        messageAboutYou,
        messageBankDetails,
      });

      if (response.data.status) {
        window.location.reload();
      }
      alert(response.data.message);
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        alert(error.response.data.message);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  // Function to handle the delete operation
  const handleDelete = async () => {
    if (deletingItemId) {
      try {
        // Make an HTTP request to delete the item with the specified ID
        const response = await axios.delete(
          `http://localhost:3001/deleteUserFromClientAdmin/${deletingItemId}`
        );

        if (response.status === 200) {
          // If the delete operation was successful, remove the item from the UI
          setRegisteredUsers((prevUsers) =>
            prevUsers.filter((user) => user._id !== deletingItemId)
          );

          // Close the delete modal
          handleHideDeleteModal();

          // Reload the page
          window.location.reload();
        } else {
          console.error("Failed to delete the item.");
        }
      } catch (error) {
        console.error("Error deleting the item:", error);
      }
    }
  };

  //function for this
  const handleReviewStatus = (userId, category, reviewStatus) => {
    setReviewData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        [category]: reviewStatus,
      },
    }));
  };

  //-----------------------------------
  // -------------------------------------
  // -------------------------------------
  // Partially reviewed function
  const [isPartialReviewLoading, setIsPartialReviewLoading] = useState(false);
  const handlePartiallyReviewed = async (userId) => {
    const userToUpdate = registeredUsers.find((user) => user._id === userId);

    let apiData = {};
    if (userToUpdate.selectMembership === "Digital User") {
      apiData = {
        isExisting: userToUpdate.isExisting,
        isTrial: userToUpdate.isTrial,
      };
    } else {
      apiData = {
        isExisting: userToUpdate.isExisting,
      };
    }
    try {
      setIsPartialReviewLoading(true);

      const res = await axios.post(
        getURLbyEndPoint("partiallyReviewed") + userId,
        apiData
      );

      if (res.data.status) {
        alert(res.data.message);
        window.location.reload();
      } else {
        alert("Something Went Wrong!!");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsPartialReviewLoading(false);
    }
  };

  //function for fully Reviewed
  const handleFullyReviewed = async (userId) => {
    try {
      const res = await axios.put(getURLbyEndPoint("fullyReviewed"), {
        userId,
      });

      if (res.data.status) {
        alert(res.data.message);
      }

      window.location.reload();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // //function for Rejected popover
  // const [documentCheckModal, setDocumentCheckModal] = useState(false);
  // const handleDocumentCheckClose = () => setDocumentCheckModal(false);
  // const handleDocumentCheckShow = () => setDocumentCheckModal(true);

  //function for Rejecting documents
  const [rejectDocumentModal, setRejectDocumentModal] = useState(false);
  const [reasonForDocumentRejection, setReasonForDocumentRejection] =
    useState("");

  const handleCloseRejectedDocumentModal = () => setRejectDocumentModal(false);
  const handleShowRejectedDocumentModal = () => setRejectDocumentModal(true);

  const handleDocumentsRejectModal = () => {
    handleShowRejectedDocumentModal();
  };

  const handleDocumentsReject = async (userId) => {
    if (!reasonForDocumentRejection) {
      alert("Reason is required to reject documents.");
    }

    try {
      const response = await axios.put(getURLbyEndPoint("rejectDocuments"), {
        userId,
        reasonForDocumentRejection,
      });

      if (response.data.status) {
        alert(response.data.message);
        handleCloseRejectedDocumentModal();
        window.location.reload();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
        alert(error.response.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };


  const handleCollaborationDocumentsApprove = async (userId) => {
    try {
      const response = await axios.put(getURLbyEndPoint("approveCollabDoc"), {
        userId,
      });

      if (response.data.status) {
        alert(response.data.message);
        window.location.reload();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
        alert(error.response.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };
  // Function to fetch registered users data
  useEffect(() => {
    const fetchRegisteredUsers = async () => {
      try {
        const response = await axios.get(getURLbyEndPoint("registerdUsers"));
        if (response.status === 200) {
          const data = response.data;

          // Process the user data
          const processedUsers = data.data.map((user) => {
            if (
              user.accountStatus === "New" &&
              user.selectMembership === "Digital User"
            ) {
              return { ...user, isTrial: false };
            }
            return user;
          });

          setRegisteredUsers([...processedUsers]);

          // Initialize review data for each user
          const initialReviewData = {};
          processedUsers.forEach((user) => {
            initialReviewData[user._id] = {
              aboutCompany: "Review",
              aboutYou: "Review",
              bankDetails: "Review",
              messageAboutCompany: "",
              messageAboutYou: "",
              messageBankDetails: "",
              documentsChecked: false,
            };
          });
          setReviewData(initialReviewData);
        } else {
          console.error("Failed to fetch registered users data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchRegisteredUsers();
  }, []);

  //pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Filtered data
  const filteredData = registeredUsers.filter((data) => {
    // Filter based on account status
    if (accountStatus !== "" && data.accountStatus !== accountStatus) {
      return false;
    }
    // Filter based on company name
    if (
      searchTerm !== "" &&
      !(
        data.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ) {
      return false;
    }
    // Filter based on isExisting
    if (userTypeExisting && data.isExisting !== true) {
      return false;
    }
    // Filter based on Trial account or not
    if (userTypeTrial && data.isTrial !== true) {
      return false;
    }

    // Filter based on collaboration account or not
    if (userTypeCollaboration && data.isCollaboration !== true) {
      return false;
    }

    // Filter based on Arbitration account or not
    if (userTypeArbitrator && data.isArbitration !== true) {
      return false;
    }

    return true;
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredData = filteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  //Existing logic
  const handleIsExistingChange = (id, value, field) => {
    const updatedData = registeredUsers.map((item) =>
      item._id === id ? { ...item, [field]: value } : item
    );
    setRegisteredUsers(updatedData);
  };

  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Profile/ Client Admin "
              backUrl="/master-profile"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={accountStatus === ""}
                  onChange={() => setAccountStatus("")}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="New"
                  name="group1"
                  type="radio"
                  checked={accountStatus === "New"}
                  onChange={() => setAccountStatus("New")}
                />
                <Form.Check
                  inline
                  label="Collaboration"
                  name="group1"
                  type="radio"
                  checked={accountStatus === "Collaboration"}
                  onChange={() => setAccountStatus("Collaboration")}
                />
                <Form.Check
                  inline
                  label="Arbitration"
                  name="group1"
                  type="radio"
                  checked={accountStatus === "Arbitration"}
                  onChange={() => setAccountStatus("Arbitration")}
                />
                <Form.Check
                  inline
                  label="Partially Reviewed"
                  name="group1"
                  type="radio"
                  checked={accountStatus === "PartiallyReviewed"}
                  onChange={() => setAccountStatus("PartiallyReviewed")}
                />
                <Form.Check
                  inline
                  label="Fully Reviewed"
                  name="group1"
                  type="radio"
                  checked={accountStatus === "FullyReviewed"}
                  onChange={() => setAccountStatus("FullyReviewed")}
                />
                <Form.Check
                  inline
                  label="Rejected"
                  name="group1"
                  type="radio"
                  checked={accountStatus === "Rejected"}
                  onChange={() => setAccountStatus("Rejected")}
                />
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search Company Name/Email"
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeExisting ? "success" : "outline-success"}
                onClick={() => setUserTypeExisting(!userTypeExisting)}
                disabled={
                  getAuthUserCookie().accessList.includes("CollaborationAccessControl")
                }
              >
                Existing
              </Button>
              <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeTrial ? "info" : "outline-info"}
                onClick={() => setUserTypeTrial(!userTypeTrial)}
                disabled={
                  getAuthUserCookie().accessList.includes("CollaborationAccessControl")
                }
              >
                Trial
              </Button>
              <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeCollaboration ? "danger" : "outline-danger"}
                onClick={handleClickCollaboration}
                active={getAuthUserCookie().accessList.includes("CollaborationAccessControl")}
              >
                Collaboration
              </Button>
              <Button
                className="rounded-5 fw-semibold me-2"
                variant={userTypeArbitrator ? "secondary" : "outline-secondary"}
                onClick={handleClickArbitration}
                active={getAuthUserCookie().accessList.includes("ArbitrationAccessControl")}
              >
                Arbitrator
              </Button>
            </div>

            <div className="table-responsive">
              <Table responsive striped bordered hover>
                <thead>
                  <tr style={{ verticalAlign: "middle", textAlign: "center" }}>
                    <th className="align-middle">Sr No</th>
                    <th className="align-middle">Membership Selection</th>
                    <th className="align-middle">About Company</th>
                    <th className="align-middle">About You</th>
                    <th className="align-middle">Bank Details</th>
                    <th className="align-middle">Communication Preferences</th>
                    <th className="align-middle">Documents and Payment</th>
                    <th className="align-middle">Review</th>
                    <th className="align-middle">Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(registeredUsers) &&
                  registeredUsers.length > 0 ? (
                    (() => {
                      const filteredData = registeredUsers.filter((data) => {
                        // Filter based on account status
                        if (
                          accountStatus !== "" &&
                          data.accountStatus !== accountStatus
                        ) {
                          return false;
                        }
                        if (
                          searchTerm !== "" &&
                          !data.companyName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return false;
                        }

                        return true;
                      });

                      if (currentFilteredData.length > 0) {
                        return currentFilteredData.map((data, index) => (
                          <tr key={indexOfFirstItem + index}>
                            <th scope="row">
                              <div style={{ minWidth: "max-content" }}>
                                {filteredData.length - indexOfFirstItem - index}
                                .{" "}
                                <div>
                                  Created at:
                                  <br />
                                  <span className="text-success">
                                    {new Intl.DateTimeFormat("en-GB", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(new Date(data.createdAt))}
                                  </span>
                                </div>
                              </div>
                            </th>
                            <td>
                              <b>{data.selectMembership}</b>
                              {data.reqType && (
                                <Badge
                                  pill
                                  className="p-2 mt-2"
                                  bg={
                                    data.reqType === "ePlatformReq"
                                      ? "success"
                                      : data.reqType === "collaborationReq"
                                      ? "primary"
                                      : data.reqType === "arbitrationReq"
                                      ? "secondary"
                                      : "info"
                                  }
                                >
                                  {data.reqType === "ePlatformReq"
                                    ? "ePlatform"
                                    : data.reqType === "collaborationReq"
                                    ? "Collaboration"
                                    : data.reqType === "arbitrationReq"
                                    ? "Arbitration"
                                    : "B2b Connect"}
                                </Badge>
                              )}
                            </td>
                            <td>
                              <div className="d-flex flex-column mb-2 max-content">
                                {/* <span className="text-secondary">
                                {data.title} {data.firstName} {data.surName}
                              </span> */}
                                <b>
                                 {data.reqType === "arbitrationReq" ? "Arbitrator Name:" : "Co. Name:"} {" "}
                                  <span className="text-secondary">
                                    {data.companyName}
                                  </span>
                                </b>
                                <b>
                                  {data.reqType === "collaborationReq" ? (
                                    <>Collaboration No: </>
                                  ) : data.reqType === "arbitrationReq" ? (
                                    <>Panel Member ID: </>
                                  ) 
                                  : (
                                    <>GST: </>
                                  )}
                                  <span className="text-secondary">
                                    {data.gstNo}
                                  </span>
                                </b>
                                <b>
                                 {data.inputNumber &&
                                 <>
                                  {data.reqType === "collaborationReq" ? (
                                    <>Registration No: </>
                                  ) : (
                                    <> IEC/CIN NO: </>
                                  )}
                              

                                  <span className="text-secondary">
                                    {data.inputNumber}
                                  </span>
                                 </>
                                  }
                                </b>
                                <b>
                                  
                                  {data.websiteAdd ?  "Web Add:" : "Phone No:"}
                                  <span className="text-secondary">
                                    {data.websiteAdd ? data.websiteAdd : data.phoneNo}
                                  </span>
                                </b>
                                <b className="d-flex">
                                  company add:{" "}
                                  <TruncatedText
                                    text={data.address1}
                                    maxChars={20}
                                  />
                                </b>
                                <h6
                                  className="mt-3"
                                  style={{
                                    color:
                                      reviewData[data._id].aboutCompany ===
                                      "Rejected"
                                        ? "red"
                                        : "blue",
                                  }}
                                >
                                  {reviewData[data._id].aboutCompany}
                                </h6>
                              {
// ((data.accountStatus === "New") || 
//   ((data.accountStatus === "Collaboration" || data.accountStatus === "Arbitration") || (data.reqType === "B2BInterest" && data.accountStatus !== "FullyReviewed")) || 
//   (data.selectMembership !== "Digital User" && data.accountStatus === "PartiallyReviewed")) && 

data.accountStatus === "New" && 
  (
                                  <div className="d-flex">
                                    <button
                                      className="mb-1 me-2 px-4 rounded-5 border-none bg-color"
                                      type="button"
                                      onClick={(e) => {
                                        handleReviewStatus(
                                          data._id,
                                          "aboutCompany",
                                          "Reviewed"
                                        );
                                        setReviewData((prevReviewData) => ({
                                          ...prevReviewData,
                                          [data._id]: {
                                            ...prevReviewData[data._id],
                                            messageAboutCompany: "", // Set the field to an empty string
                                          },
                                        }));
                                      }}
                                    >
                                      Yes
                                    </button>
                                    {/* 
About Company Status setup------------------------------------------------
-------------------------------------------------------------
------------------------------------------------------------- */}

                                      <div>
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="bottom"
                                          container={this}
                                          overlay={
                                            <Popover
                                              id={`popover-positioned-bottom`}
                                              show={true}
                                            >
                                              <Popover.Body>
                                                <Form>
                                                  <div className="mb-3">
                                                    <FloatingLabel
                                                      controlId="floatingTextarea2"
                                                      label="Input a proper reason"
                                                    >
                                                      <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        style={{
                                                          maxHeight: "100px",
                                                          height: "100px",
                                                        }}
                                                        value={reason}
                                                        onChange={(e) => {
                                                          setReason(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </FloatingLabel>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex justify-content-between gap-2">
                                                      <Button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e) => {
                                                          handleReviewStatus(
                                                            data._id,
                                                            "aboutCompany",
                                                            "Rejected"
                                                          );
                                                          handleReviewStatus(
                                                            data._id,
                                                            "messageAboutCompany",
                                                            reason
                                                          );
                                                          setReason("");
                                                          document.body.click();
                                                        }}
                                                      >
                                                        DISAPPROVED
                                                      </Button>
                                                      <Button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                          document.body.click();
                                                        }}
                                                      >
                                                        CANCEL
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </Form>
                                              </Popover.Body>
                                            </Popover>
                                          }
                                          rootClose={true}
                                          onHide={() =>
                                            setPopoverVisible(false)
                                          }
                                        >
                                          <Button
                                            className="px-4 mb-1 py-0 rounded-5 border-none"
                                            type="button"
                                            style={{
                                              backgroundColor: "#0b3b5d",
                                            }}
                                          >
                                            No
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  )}

                                {reviewData[data._id].messageAboutCompany}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column mb-2 max-content">
                                <b>
                                  Name:
                                  <span className="text-secondary">
                                    {" "}
                                    {data.title} {data.firstName} {data.surName}
                                  </span>
                                </b>
                                <b>
                                  Role:{" "}
                                  <span className="text-secondary">
                                    {data.role}
                                  </span>
                                </b>
                                <b>
                                  Email:{" "}
                                  <span className="text-secondary">
                                    {data.email}{" "}
                                  </span>
                                </b>

                                <h6
                                  className="mt-3"
                                  style={{
                                    color:
                                      reviewData[data._id].aboutYou ===
                                      "Rejected"
                                        ? "red"
                                        : "blue",
                                  }}
                                >
                                  {reviewData[data._id].aboutYou}
                                </h6>
                                {

                                  console.log((data.accountStatus === "Collaboration" || data.reqType === "B2BInterest"))
                                }
                                {
    //                             ((data.accountStatus === "New") || 
    // ((data.accountStatus === "Collaboration" || data.accountStatus === "Arbitration") || 
    //   (data.reqType === "B2BInterest" && data.accountStatus !== "FullyReviewed")
    // ) || 
    // (data.selectMembership !== "Digital User" && data.accountStatus === "PartiallyReviewed")) 
    data.accountStatus === "New" && ( 
                                    <div className="d-flex">
                                      <button
                                        className="px-4 me-2 mb-1 rounded-5 border-none bg-color"
                                        type="button"
                                        onClick={(e) => {
                                          handleReviewStatus(
                                            data._id,
                                            "aboutYou",
                                            "Reviewed"
                                          );
                                          setReviewData((prevReviewData) => ({
                                            ...prevReviewData,
                                            [data._id]: {
                                              ...prevReviewData[data._id],
                                              messageAboutYou: "", // Set the field to an empty string
                                            },
                                          }));
                                        }}
                                      >
                                        Yes
                                      </button>
                                      {/* 
About You Status setup------------------------------------------------
-------------------------------------------------------------
------------------------------------------------------------- */}

                                      <div>
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="bottom"
                                          container={this}
                                          overlay={
                                            <Popover
                                              id={`popover-positioned-bottom`}
                                              show={true}
                                            >
                                              <Popover.Body>
                                                <Form>
                                                  <div className="mb-3">
                                                    <FloatingLabel
                                                      controlId="floatingTextarea2"
                                                      label="Input a proper reason"
                                                    >
                                                      <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        style={{
                                                          maxHeight: "100px",
                                                          height: "100px",
                                                        }}
                                                        value={reason}
                                                        onChange={(e) => {
                                                          setReason(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </FloatingLabel>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex justify-content-between gap-2">
                                                      <Button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e) => {
                                                          handleReviewStatus(
                                                            data._id,
                                                            "aboutYou",
                                                            "Rejected"
                                                          );
                                                          handleReviewStatus(
                                                            data._id,
                                                            "messageAboutYou",
                                                            reason
                                                          );
                                                          setReason("");
                                                          document.body.click();
                                                        }}
                                                      >
                                                        DISAPPROVED
                                                      </Button>
                                                      <Button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                          document.body.click();
                                                        }}
                                                      >
                                                        CANCEL
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </Form>
                                              </Popover.Body>
                                            </Popover>
                                          }
                                          rootClose={true}
                                          onHide={() =>
                                            setPopoverVisible(false)
                                          }
                                        >
                                          <Button
                                            className="px-4 mb-1 py-0 rounded-5 border-none"
                                            type="button"
                                            style={{
                                              backgroundColor: "#0b3b5d",
                                            }}
                                          >
                                            No
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  )}

                                {reviewData[data._id].messageAboutYou}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column mb-2 max-content">
                                <b>
                                  Registered Ac. No:{" "}
                                  <span className="text-secondary">
                                    {data.registeredAccountNo}{" "}
                                  </span>
                                </b>
                                <b>
                                  Bank Name:{" "}
                                  <span className="text-secondary">
                                    {data.registeredBank}{" "}
                                  </span>
                                </b>
                                <b>
                                  IFSC Code:{" "}
                                  <span className="text-secondary">
                                    {data.IFSCCode}
                                  </span>
                                </b>

                                <h6
                                  className="mt-3"
                                  style={{
                                    color:
                                      reviewData[data._id].bankDetails ===
                                      "Rejected"
                                        ? "red"
                                        : "blue",
                                  }}
                                >
                                  {reviewData[data._id].bankDetails}
                                </h6>
                                {
    //                             ((data.accountStatus === "New") || 
    // ((data.accountStatus === "Collaboration" || data.accountStatus === "Arbitration") || 
    //   (data.reqType === "B2BInterest" && data.accountStatus !== "FullyReviewed")
    // ) || 
    // (data.selectMembership !== "Digital User" && data.accountStatus === "PartiallyReviewed")) && 

    data.accountStatus === "New" && (     
                                    <div className="d-flex">
                                      <button
                                        className="px-4 me-2 mb-1 rounded-5 border-none bg-color"
                                        type="button"
                                        onClick={(e) => {
                                          handleReviewStatus(
                                            data._id,
                                            "bankDetails",
                                            "Reviewed"
                                          );
                                          setReviewData((prevReviewData) => ({
                                            ...prevReviewData,
                                            [data._id]: {
                                              ...prevReviewData[data._id],
                                              messageBankDetails: "", // Set the field to an empty string
                                            },
                                          }));
                                        }}
                                      >
                                        Yes
                                      </button>
                                      {/*                               
                              About Company Status setup------------------------------------------------
-------------------------------------------------------------
-------------------------------------------------------------  */}
                                      <div>
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="bottom"
                                          container={this}
                                          overlay={
                                            <Popover
                                              id={`popover-positioned-bottom`}
                                              show={true}
                                            >
                                              <Popover.Body>
                                                <Form>
                                                  <div className="mb-3">
                                                    <FloatingLabel
                                                      controlId="floatingTextarea2"
                                                      label="Input a proper reason"
                                                    >
                                                      <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        style={{
                                                          maxHeight: "100px",
                                                          height: "100px",
                                                        }}
                                                        value={reason}
                                                        onChange={(e) => {
                                                          setReason(
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    </FloatingLabel>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex justify-content-between gap-2">
                                                      <Button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e) => {
                                                          handleReviewStatus(
                                                            data._id,
                                                            "bankDetails",
                                                            "Rejected"
                                                          );
                                                          handleReviewStatus(
                                                            data._id,
                                                            "messageBankDetails",
                                                            reason
                                                          );
                                                          setReason("");
                                                          document.body.click();
                                                        }}
                                                      >
                                                        DISAPPROVED
                                                      </Button>
                                                      <Button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                          document.body.click();
                                                        }}
                                                      >
                                                        CANCEL
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </Form>
                                              </Popover.Body>
                                            </Popover>
                                          }
                                          rootClose={true}
                                          onHide={() =>
                                            setPopoverVisible(false)
                                          }
                                        >
                                          <Button
                                            className="px-4 mb-1 py-0 rounded-5 border-none"
                                            type="button"
                                            style={{
                                              backgroundColor: "#0b3b5d",
                                            }}
                                          >
                                            No
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  )}

                                {reviewData[data._id].messageBankDetails}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column mb-2 max-content">
                                <div className="d-flex mb-3">
                                  <div className="d-flex align-items-center">
                                    {data.throughEmail ? (
                                      <AiFillCheckSquare className="icon-size" />
                                    ) : (
                                      <AiFillCloseSquare className="icon-size" />
                                    )}
                                    <span className="w-75 ms-2">
                                      <b>Emails?</b>
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    {data.throughCalls ? (
                                      <AiFillCheckSquare className="icon-size" />
                                    ) : (
                                      <AiFillCloseSquare className="icon-size" />
                                    )}
                                    <span className=" ms-2 w-75">
                                      <b>Calls?</b>
                                    </span>
                                  </div>
                                </div>
                                {/* 
                                //is existing setup---------------------------------------------------------------------------------
                                ----------------------------------------------------------------------------------------------------
                                --------------------------------------- -----------------------------------------------------------*/}
                                {
  //                               ((data.accountStatus === "New") || 
  // ((data.accountStatus === "Collaboration" || data.accountStatus === "Arbitration") || 
  //   (data.reqType === "B2BInterest" && data.accountStatus !== "FullyReviewed")
  // ) || 
  // (data.selectMembership !== "Digital User" && data.accountStatus === "PartiallyReviewed")) && 
  data.accountStatus === "New" && 
(
                                    <div>
                                      <div>
                                        <h6>Is Existing?</h6>
                                      </div>

                                      <div className="d-flex  mb-3">
                                        <div className="d-flex align-items-center me-3">
                                          <Form.Check
                                            type="radio"
                                            name={`${data._id}existing`}
                                            id="existingYes"
                                            className="me-2"
                                            checked={data.isExisting}
                                            onChange={() =>
                                              handleIsExistingChange(
                                                data._id,
                                                true,
                                                "isExisting"
                                              )
                                            }
                                          />
                                          <h6>Yes</h6>
                                        </div>
                                        <div className="d-flex align-items-center me-3">
                                          <Form.Check
                                            type="radio"
                                            name={`${data._id}existing`}
                                            id="existingNo"
                                            className="me-2"
                                            checked={!data.isExisting}
                                            onChange={() =>
                                              handleIsExistingChange(
                                                data._id,
                                                false,
                                                "isExisting"
                                              )
                                            }
                                          />
                                          <h6>No</h6>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {/* //is trial setup---------------------------------------------------------------------------------
                                ----------------------------------------------------------------------------------------------------
                                --------------------------------------- ----------------------------------------------------------- */}
                                  { data.accountStatus === "New" &&
                               
//                                 ((data.accountStatus === "New") || 
//   ((data.accountStatus === "Collaboration" || data.accountStatus === "Arbitration") || 
//     (data.reqType === "B2BInterest" && data.accountStatus !== "FullyReviewed")
//   ) || 
//   (data.selectMembership !== "Digital User" && data.accountStatus === "PartiallyReviewed")
// ) && 

                                    data.selectMembership ===
                                      "Digital User" && (
                                      <div>
                                        <div>
                                          <h6>Is Trial?</h6>
                                        </div>

                                        <div className="d-flex  mb-3">
                                          <div className="d-flex align-items-center me-3">
                                            <Form.Check
                                              type="radio"
                                              name={`${data._id}trial`}
                                              id="trialYes"
                                              className="me-2"
                                              checked={data.isTrial}
                                              onChange={() =>
                                                handleIsExistingChange(
                                                  data._id,
                                                  true,
                                                  "isTrial"
                                                )
                                              }
                                            />
                                            <h6>Yes</h6>
                                          </div>
                                          <div className="d-flex align-items-center me-3">
                                            <Form.Check
                                              type="radio"
                                              name={`${data._id}trial`}
                                              id="trialNo"
                                              className="me-2"
                                              checked={!data.isTrial}
                                              onChange={() =>
                                                handleIsExistingChange(
                                                  data._id,
                                                  false,
                                                  "isTrial"
                                                )
                                              }
                                            />
                                            <h6>No</h6>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                { data.accountStatus === "New" && (
//                                 ((data.accountStatus === "New") || 
//    ((data.accountStatus === "Collaboration" || data.accountStatus === "Arbitration") || 
//      (data.reqType === "B2BInterest" && data.accountStatus !== "FullyReviewed")
//    ) || 
//    (data.selectMembership !== "Digital User" && data.accountStatus === "PartiallyReviewed")
//  ) &&  
 
                                    <div className="d-flex">
                                      <div className="flex-grow-1 d-flex flex-column align-items-center mb-3 column-gap-2 mx-2">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            // Check if all four review sections are reviewed
                                            const allReviewed =
                                              reviewData[data._id]
                                                .aboutCompany === "Reviewed" &&
                                              reviewData[data._id].aboutYou ===
                                                "Reviewed" &&
                                              reviewData[data._id]
                                                .bankDetails === "Reviewed";

                                            // Enable the button only if all sections are reviewed
                                            if (allReviewed) {
                                              handlePartiallyReviewed(data._id);
                                            }
                                          }}
                                          className="border-none icon-size"
                                          style={{
                                            backgroundColor: "transparent",
                                            color:
                                              data.accountStatus ===
                                                "PartiallyReviewed" ||
                                              data.accountStatus ===
                                                "FullyReviewed"
                                                ? "green"
                                                : reviewData[data._id]
                                                    .aboutCompany !==
                                                    "Reviewed" ||
                                                  reviewData[data._id]
                                                    .aboutYou !== "Reviewed" ||
                                                  reviewData[data._id]
                                                    .bankDetails !== "Reviewed"
                                                ? "lightgrey"
                                                : "#a2c579",
                                          }}
                                          disabled={
                                            isPartialReviewLoading || // Disable if loading
                                            ((reviewData[data._id]
                                              .aboutCompany !== "Reviewed" ||
                                              reviewData[data._id].aboutYou !==
                                                "Reviewed" ||
                                              reviewData[data._id]
                                                .bankDetails !== "Reviewed") &&
                                              data.accountStatus !==
                                                "PartiallyReviewed")
                                          }
                                        >
                                          <AiFillCheckCircle className="h-100 w-100" />
                                        </button>

                                        {/* <b>Partially Reviewed</b> */}
                                      </div>
                                      {(reviewData[data._id].aboutCompany ===
                                        "Rejected" ||
                                        reviewData[data._id].aboutYou ===
                                          "Rejected" ||
                                        reviewData[data._id].bankDetails ===
                                          "Rejected") &&
                                        accountStatus !==
                                          "PartiallyReviewed" && (
                                          <div className="flex-grow-1 d-flex flex-column align-items-center mb-3 column-gap-2 mx-2 cursor-pointer">
                                            <AiFillCloseCircle
                                              onClick={() => {
                                                if (
                                                  reviewData[data._id]
                                                    .aboutCompany !==
                                                    "Review" &&
                                                  reviewData[data._id]
                                                    .aboutYou !== "Review" &&
                                                  reviewData[data._id]
                                                    .bankDetails !== "Review"
                                                ) {
                                                  handleRejected(
                                                    data._id,
                                                    reviewData[data._id]
                                                      .aboutCompany,
                                                    reviewData[data._id]
                                                      .aboutYou,
                                                    reviewData[data._id]
                                                      .bankDetails,
                                                    reviewData[data._id]
                                                      .messageAboutCompany,
                                                    reviewData[data._id]
                                                      .messageAboutYou,
                                                    reviewData[data._id]
                                                      .messageBankDetails
                                                  );
                                                } else {
                                                  alert(
                                                    "Review all data to reject request"
                                                  );
                                                }
                                              }}
                                              className={`icon-size text-danger ${
                                                accountStatus !==
                                                "fully Reviewed"
                                                  ? "grey-bg"
                                                  : ""
                                              }`}
                                              disabled={false}
                                            />
                                            {/* <b>Reject</b> */}
                                          </div>
                                        )}
                                    </div>
                                  )}
                              </div>

                              {(data.accountStatus === "PartiallyReviewed" ||
                              data.accountStatus === "FullyReviewed") ? (
                                <div className="fw-bold text-center">
                                  Partially Reviewed at:
                                  <br />
                                  <span className="text-success">
                                    {new Intl.DateTimeFormat("en-GB", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(
                                      new Date(data.partiallyReviewedAt)
                                    )}
                                  </span>
                                </div>
                              ) : null}
                              {data.accountStatus === "Rejected" && (
                                <div className="fw-bold text-center">
                                  Application Rejected at:
                                  <br />
                                  <span className="text-danger">
                                    {new Intl.DateTimeFormat("en-GB", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(new Date(data.detailsRejectedAt))}
                                  </span>
                                </div>
                              )}
                            </td>
                            {/* 
                            --------------------------------Documents section-----------------------------------------
                            --------------------------------------------------------------------------------------------
                            ---------------------------------------------------------------------------------------------
                            ------------------------------------------------------------------------------------------- */}
                               
                                <td>
  <div className="max-content">
    {data.documents && Object.keys(data.documents).length > 0 ? (
      <>
        {Object.keys(data.documents).map((e) => (
          <>
            {e === "clientId" ||
            e === "_id" ||
            e === "email" ||
            e === "__v" ||
            e === "createdAt" ||
            e === "updatedAt" ||
            e === "passports" ? (
              ""
            ) : (
              <span className="d-block" key={e}>
                <a
                  href="#"
                  onClick={() =>
                    openModal(
                      (() => {
                        switch (e) {
                          case "membershipForm":
                            return "Membership Form";
                          case "nonMemberForm":
                            return "Non-Member Form";
                          case "coverLetter":
                            return "Cover Letter";
                          case "indemnityBond":
                            return "Indemnity Bond";
                          case "kyc":
                            return "KYC";
                          case "gstCertOrPanCopy":
                            return "GST Certificate Or PAN Copy";
                          case "iecCertificate":
                            return "IEC Certificate";
                          case "certificationOfRegistration":
                            return "Certificate Of Registration";
                          case "incorpration_AOA_MOA":
                            return "Certificate of Incorporation/AOA/MOA";
                          case "passportCopies":
                            return "Passport Photo Copies";
                          case "paymentProof":
                            return "Payment Proof";
                          case "registrationForm":
                            return "Registration Form";
                          case "companyProfile":
                            return "Company Profile";
                          case "collaborationAgreement":
                            return "Collaboration Agreement";
                          case "businessLicense":
                            return "Business License";
                            case "invitationLetter":
                              return "invitation Letter";
                          default:
                            return "Unknown file";
                        }
                      })(),
                      data.documents[e]
                    )
                  }
                >
                  {(() => {
                    switch (e) {
                      case "membershipForm":
                        return "Membership Form";
                      case "nonMemberForm":
                        return "Non-Member Form";
                      case "coverLetter":
                        return "Cover Letter";
                      case "indemnityBond":
                        return "Indemnity Bond";
                      case "kyc":
                        return "KYC";
                      case "gstCertOrPanCopy":
                        return "GST Certificate Or PAN Copy";
                      case "iecCertificate":
                        return "IEC Certificate";
                      case "certificationOfRegistration":
                        return "Certificate Of Registration";
                      case "incorpration_AOA_MOA":
                        return "Certificate of Incorporation/AOA/MOA";
                      case "passportCopies":
                        return "Passport Photo Copies";
                      case "paymentProof":
                        return "Payment Proof";
                      case "registrationForm":
                        return "Registration Form";
                      case "companyProfile":
                        return "Company Profile";
                      case "collaborationAgreement":
                        return "Collaboration Agreement";
                      case "businessLicense":
                        return "Business License";
                      case "invitationLetter":
                        return "invitation Letter";
                      default:
                        return "Unknown file";
                    }
                  })()}
                </a>
              </span>
            )}
          </>
        ))}

        {/* Handling the Passports Array */}
        {data.documents.passports && data.documents.passports.length > 0 && (
  <div>
    {data.documents.passports.map((passport, index) => (
      <div key={index} className="d-block">
        <h6>Delegate {index + 1}</h6>
        <span>Full Name: {passport.firstName} {passport.lastName}</span>
        <br />
        <span>Passport No: {passport.passportNo}</span>
        <br />
        
        {/* Passport Copy Link with Modal */}
        <span>
          Passport Copy:{" "}
          <a
            href="#"
            onClick={() =>
              openModal("Passport Copy", passport.passportCopy)
            }
          >
            View Passport Copy
          </a>
        </span>
        <br />

        {/* Delegate Photo Link with Modal */}
        <span>
          Delegate Photo:{" "}
          <a
            href="#"
            onClick={() =>
              openModal("Delegate Photo", passport.delegatePhoto)
            }
          >
            View Delegate Photo
          </a>
        </span>
      </div>
    ))}
  </div>
)}

      </>
    ) : (
      <span>No data is available</span>
    )}
  </div>
  {data.documents !== null &&
                                data.accountStatus !== "FullyReviewed" && (
                                  <div className="d-flex justify-content-around mt-3">
                                    <Button
                                      className="m-0 p-0 border-none"
                                      style={{
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                      }}
                                      onClick={(e) => {
                                        handleReviewStatus(
                                          data._id,
                                          "documentsChecked",
                                          true
                                        );
                                      }}
                                    >
                                      <AiFillCheckCircle className="w-100 icon-size green-color" />
                                    </Button>

                                    <Button
                                      className="m-0 p-0 border-none"
                                      style={{
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                      }}
                                      onClick={handleDocumentsRejectModal}
                                    >
                                      <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                    </Button>
                                    <Modal
                                      show={rejectDocumentModal}
                                      onHide={handleCloseRejectedDocumentModal}
                                      size="lg"
                                      aria-labelledby="contained-modal-title-vcenter"
                                      centered
                                    >
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          handleDocumentsReject(data._id);
                                        }}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Document Rejection
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <div>
                                            <h6>
                                              *Add Resone for Rejecting
                                              Documents of {""}"
                                              {data.companyName}"
                                            </h6>{" "}
                                            <div
                                              style={{
                                                minWidth: "max-content",
                                              }}
                                              className="d-flex flex-column mb-2"
                                            >
                                              <FloatingLabel
                                                controlId="floatingTextarea2"
                                                label="Input a proper reason"
                                              >
                                                <Form.Control
                                                  as="textarea"
                                                  required
                                                  placeholder="Leave a comment here"
                                                  style={{
                                                    minHeight: "100px",
                                                  }}
                                                  value={
                                                    reasonForDocumentRejection
                                                  }
                                                  onChange={(e) =>
                                                    setReasonForDocumentRejection(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </FloatingLabel>
                                              {/* <div className="me-2">
                                              <input
                                                style={{
                                                  height: "24px",
                                                  width: "24px",
                                                }}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkboxNoLabel"
                                                defaultValue
                                                aria-label="..."
                                                label="hello"
                                                required
                                              />
                                            </div>
                                            <div>Membership Form</div> */}
                                            </div>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="danger"
                                            type="submit"
                                          >
                                            Reject
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            onClick={
                                              handleCloseRejectedDocumentModal
                                            }
                                          >
                                            Close
                                          </Button>
                                        </Modal.Footer>
                                      </Form>
                                    </Modal>
                                  </div>
                                )
                                || data.documents !== null && data.accountStatus === "FullyReviewed" 
                                && data.selectMembership === "Collaboration" && !data.isDocumentApproved 
                                && (
                                  <div className="d-flex justify-content-around mt-3">
                                    <Button
                                      className="m-0 p-0 border-none"
                                      style={{
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                      }}
                                      onClick={()=> handleCollaborationDocumentsApprove(data._id)}
                                    >
                                      <AiFillCheckCircle className="w-100 icon-size green-color" />
                                    </Button>

                                    <Button
                                      className="m-0 p-0 border-none"
                                      style={{
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                      }}
                                      onClick={handleDocumentsRejectModal}
                                    >
                                      <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                    </Button>
                                    <Modal
                                      show={rejectDocumentModal}
                                      onHide={handleCloseRejectedDocumentModal}
                                      size="lg"
                                      aria-labelledby="contained-modal-title-vcenter"
                                      centered
                                    >
                                      <Form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          handleDocumentsReject(data._id);
                                        }}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            Document Rejection
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <div>
                                            <h6>
                                              *Add Resone for Rejecting
                                              Documents of {""}"
                                              {data.companyName}"
                                            </h6>{" "}
                                            <div
                                              style={{
                                                minWidth: "max-content",
                                              }}
                                              className="d-flex flex-column mb-2"
                                            >
                                              <FloatingLabel
                                                controlId="floatingTextarea2"
                                                label="Input a proper reason"
                                              >
                                                <Form.Control
                                                  as="textarea"
                                                  required
                                                  placeholder="Leave a comment here"
                                                  style={{
                                                    minHeight: "100px",
                                                  }}
                                                  value={
                                                    reasonForDocumentRejection
                                                  }
                                                  onChange={(e) =>
                                                    setReasonForDocumentRejection(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </FloatingLabel>
                                              {/* <div className="me-2">
                                              <input
                                                style={{
                                                  height: "24px",
                                                  width: "24px",
                                                }}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkboxNoLabel"
                                                defaultValue
                                                aria-label="..."
                                                label="hello"
                                                required
                                              />
                                            </div>
                                            <div>Membership Form</div> */}
                                            </div>
                                          </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="danger"
                                            type="submit"
                                          >
                                            Reject
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            onClick={
                                              handleCloseRejectedDocumentModal
                                            }
                                          >
                                            Close
                                          </Button>
                                        </Modal.Footer>
                                      </Form>
                                    </Modal>
                                  </div>
                                )
                                }
                              {data.documentsRejectedAt && (
                                <div className="fw-bold text-center">
                                  Documents Rejected at:
                                  <br />
                                  <span className="text-danger">
                                    {new Intl.DateTimeFormat("en-GB", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(
                                      new Date(data.documentsRejectedAt)
                                    )}
                                  </span>
                                </div>
                              )}
</td>

                            <td className="align-middle">
                              <div className="d-flex flex-column align-items-center mx-2 mb-2 max-content">
                                <button
                                  type="button"
                                  className="border-none icon-size"
                                  style={{
                                    backgroundColor: "transparent",
                                    color:
                                      data.accountStatus === "FullyReviewed"
                                        ? "green" // Set to green when accountStatus is "FullyReviewed"
                                        : reviewData[data._id].documentsChecked
                                        ? "#a2c579"
                                        : "",
                                  }}
                                  disabled={
                                    !reviewData[data._id].documentsChecked
                                  }
                                  onClick={() => handleFullyReviewed(data._id)}
                                >
                                  <AiFillCheckCircle className="h-100 w-100" />
                                </button>

                                <b className="text-center">Fully Reviewed</b>
                              </div>

                              {/* <div className="d-flex flex-column align-items-center mx-2 mb-2">
                              <AiFillCloseCircle
                                className="icon-size"
                                style={{ color: "red" }}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => handleShowDeleteModal(data._id)}
                              />
                              <b>Delete</b>
                              {
                                // {deletingItemId && (

                                <div
                                  key={data._id}
                                  className="modal"
                                  id="exampleModal"
                                  tabIndex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                      <div className="modal-body">
                                        <h5>
                                          Are you sure you want to delete this?{" "}
                                        </h5>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                          onClick={handleHideDeleteModal}
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={handleDelete}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div> */}
                            </td>
                            <td className="text-center">
                              {data.accountStatus === "FullyReviewed" && (
                                <div className="fw-bold text-center">
                                  Fully Reviewed at:
                                  <br />
                                  <span className="text-success">
                                    {new Intl.DateTimeFormat("en-GB", {
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }).format(new Date(data.fullyReviewedAt))}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        ));
                      } else {
                        return (
                          <tr>
                            <td colSpan="9" className="text-center">
                              No matching data
                            </td>
                          </tr>
                        );
                      }
                    })()
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No Records Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
        <FileViewModal
          show={showModal}
          onHide={closeModal}
          title={modalTitle}
          fileLink={file}
        />
      </Layout>
    </>
  );
};

export default ClientAdmin;

// const PopoverContent = ({ target, show, handleClosePopover, userId }) => {
//   const [reason, setReason] = useState("");

//   const [reviewData, setReviewData] = useState({
//     aboutCompany: "Review",
//     aboutYou: "Review",
//     bankDetails: "Review",
//     messageAboutCompany: "",
//     messageAboutYou: "",
//     messageBankDetails: "",
//   });

//   const handleSaveReason = () => {
//     // Update the reviewData state based on the user's ID
//     setReviewData((prevReviewData) => ({
//       ...prevReviewData,
//       [userId]: {
//         ...prevReviewData[userId],
//         messageAboutCompany: reason,
//         messageAboutYou: reason,
//         messageBankDetails: reason,
//       },
//     }));

//     handleClosePopover();
//   };

//   return (
//     <Overlay show={show} target={target} placement="bottom">
//       <Popover id={`popover-${userId}`}>
//         <Popover.Body>
//           <div>
//             <div className="mb-3">
//               <textarea
//                 style={{
//                   resize: "none",
//                 }}
//                 className="form-control"
//                 aria-label="With textarea"
//                 value={reason}
//                 onChange={(e) => setReason(e.target.value)}
//                 placeholder="Input a proper reason"
//               />
//             </div>
//             <div>
//               <div className="d-flex justify-content-between gap-2">
//                 <button
//                   type="button"
//                   className="btn btn-primary"
//                   onClick={handleSaveReason}
//                   disabled={!reason.trim()}
//                 >
//                   DISAPPROVED
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-danger"
//                   onClick={handleClosePopover}
//                 >
//                   CANCEL
//                 </button>
//               </div>
//             </div>
//           </div>
//         </Popover.Body>
//       </Popover>
//     </Overlay>
//   );
// };

// Function to fetch registered users data by email
// Function to fetch registered users data by email
// const fetchRegistrationDocsByEmail = async (email) => {
//   try {
//     const response = await axios.get(
//       getURLbyEndPoint("getAllRegistrationDocs")
//     ); // Replace with the correct API URL
//     if (response.status === 200) {
//       const data = response.data;
//       const filteredData = data.data.filter((doc) => doc.email === email);

//       if (filteredData.length > 0) {
//         const userData = filteredData[0];
//         console.log(`Registered Files for ${email}:`, userData);

//         // Extract relevant information
//         const {
//           membershipForm,
//           coverLetter,
//           indemnityBond,
//           gstCertOrPanCopy,
//           iecCertificate,
//           certificationOfRegistration,
//           incorpration_AOA_MOA,
//           passportCopies,
//           paymentProof,
//         } = userData;

//         return {
//           membershipForm,
//           coverLetter,
//           indemnityBond,
//           gstCertOrPanCopy,
//           iecCertificate,
//           certificationOfRegistration,
//           incorpration_AOA_MOA,
//           passportCopies,
//           paymentProof,
//         };
//       } else {
//         console.log(`No registration documents found for email: ${email}`);
//         return null;
//       }
//     } else {
//       console.error("Failed to fetch registered users Files.");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     return null;
//   }
// };

const fullyApprovedPopover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Popover right</Popover.Header>
    <Popover.Body>
      And here's some <strong>amazing</strong> content. It's very engaging.
      right?
    </Popover.Body>
  </Popover>
);

{
  /* <div className="mb-3">
              <AddExistingModal
                show={showModal}
                handleClose={handleCloseModal}
              />
            </div> */
}
//add exting Modal

//add exiting modal logic
//  const [showModal, setShowModal] = useState(false);
//  const handleCloseModal = () => {
//    setShowModal(false);
//  };
// const AddExistingModal = () => {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow}>
//         Add Existing
//       </Button>

//       <Modal
//         show={show}
//         onHide={handleClose}
//         size="xl"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>ADD EXISTING</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div>
//             <h4 className="mb-3">Registration Details</h4>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex w-50">
//                 <Form.Label className="fw-semibold fs-6 w-25">
//                   Email*
//                 </Form.Label>
//                 <Form.Control required type="text" style={{ width: "70%" }} />
//               </div>
//               <div className="d-flex w-50 justify-content-end">
//                 <Form.Label className="fw-semibold fs-6 w-25">
//                   CIN No./IEC No.*
//                 </Form.Label>
//                 <Form.Control required type="text" style={{ width: "70%" }} />
//               </div>{" "}
//             </div>
//             <h4 className="mb-3">Membership Type</h4>
//             <div className="d-flex w-100 mb-2">
//               <div className="d-flex w-75">
//                 <Form.Label className="fw-semibold fs-6 w-25">
//                   Select Membership*
//                 </Form.Label>
//                 <Form.Select required style={{ width: "60%" }}>
//                   <option value="" disabled selected>
//                     Select Membership
//                   </option>
//                   <option value="Small Business (Associate)">
//                     Small Business (Associate)
//                   </option>
//                   <option value="Start-Up">Start-Up</option>
//                   <option value="Corporate">Corporate</option>
//                   <option value="Corporate +">Corporate +</option>
//                   <option value="Overseas">Overseas</option>
//                   <option value="Non-Profit Organization">
//                     Non-Profit Organization
//                   </option>
//                   <option value="Digital User">Digital User</option>
//                 </Form.Select>{" "}
//               </div>
//             </div>
//             {/* --------------ABout Company--------------------
//             ----------------------------------------------
//             ---------------------------------------------- */}
//             <h4 className="mb-3">About Company</h4>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *COMPANY NAME
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*GST NO.</Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>{" "}
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *WEBSITE LINK
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *NUMBER OF EMPLOYEES
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>{" "}
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *ADDRESS 1
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *ADDRESS 2
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>{" "}
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*CITY</Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*STATE</Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>{" "}
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*COUNTRY</Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*PINCODE</Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>{" "}
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *BUSINESS CATEGORY
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   * HOW DID YOU HEAR ABOUT US ?
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50 pe-4">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *WHY YOU WANT TO JOIN AS A MEMBER OF THE AECCI ?
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>
//             {/* --------------About You--------------------
//             ----------------------------------------------
//             ---------------------------------------------- */}
//             <h4 className="mb-3">About User</h4>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*TITLE</Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *FIRST NAME
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*SURNAME</Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*ROLE</Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*EMAIL</Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*PASSWORD</Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*EMAIL</Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">*PASSWORD</Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>{" "}
//             <div className="d-flex w-100 mb-3 gap-5 ">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *MOBILE NO.
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   COMPANY TEL NO.
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>
//             {/* --------------Bank Details--------------------
//             ----------------------------------------------
//             ---------------------------------------------- */}
//             <h4 className="mb-3">Bank Details</h4>hrogh
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *REGISTERED BANK
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//               <div className="d-flex flex-column w-50">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *ACCOUNT NO.
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>{" "}
//             </div>
//             <div className="d-flex w-100 mb-3 gap-5">
//               <div className="d-flex flex-column w-50 pe-4">
//                 <Form.Label className="fw-semibold fs-6 ">
//                   *IFSC CODE
//                 </Form.Label>
//                 <Form.Control required type="text" />
//               </div>
//             </div>
//             {/* --------------Communication Preferences--------------------
//             ----------------------------------------------
//             ---------------------------------------------- */}
//             <h4 className="mb-3">Communication Preferences</h4>
//             <div className="d-flex gap-3">
//               <div className="d-flex align-items-center gap-2">
//                 <Form.Check type="checkbox" id="emailCheckbox" required />
//                 <h6>THROUG EMAILS ?</h6>
//               </div>
//               <div className="d-flex gap-2">
//                 <Form.Check type="checkbox" id="passwordCheckbox" required />
//                 <h6>THROUGH TELEPHONE CALLS ?</h6>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleClose}>
//             Create New User
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };
